.message {
    &--container {
        background: #ffe8e8;
        padding: 14px 13px;
        border: 1px solid red;
        border-radius: 8px;
        &--error {
            background: #ffe8e8;
            border: 1px solid red;
        }
        &--success {
            border: 1px solid #349850;
            background: #d2f7d6;
        }
    }
}