.button {
    &--large {
        width: 100%;
        text-align: center;
        padding: 18px 0px;
        background: #34984f;
        color: #fff;
        font-size: 18px;
        line-height: 26px;
        border-radius: 8px;
        box-shadow: inset 0px 0px 40px rgb(0 0 0 / 0%);
        transition: 0.3s all ease-in-out;
        &:hover {
            box-shadow: inset 500px 0px 40px rgb(0 0 0 / 15%);
            cursor: pointer;
        }
    }

    &--small {
        text-align: center;
        padding: 8px 14px;
        background: #34984f;
        color: #fff;
        font-size: 16px;
        line-height: 22px;
        border-radius: 8px;
        box-shadow: inset 0px 0px 40px rgb(0 0 0 / 0%);
        transition: 0.3s all ease-in-out;
        &:hover {
            box-shadow: inset 500px 0px 40px rgb(0 0 0 / 15%);
            cursor: pointer;
        }
    }
}