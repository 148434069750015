.sidebar {
    &--container {
        width: 450px;
        min-width: 450px;
        max-width: 450px;
        background: #fbfbfb;
        padding: 40px 30px;
        box-sizing: border-box;
        position: sticky;
        top: 20px;
        height: calc(100vh - 40px);
        overflow: scroll;
        border-radius: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-right: 20px;
        box-shadow: 0px 4px 40px #e4e4e4;
    }

}