.login {
    min-width: 100%;
    min-height: 100vh;
    background: #FFF8E6;

    &--header {
        img {
            width: 140px;
            margin-top: 20px;
            margin-left: 20px;
        }
    }
    &--wrapper {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 80%;
        left: 10%;
    }
    &--container {
        width: 500px;
        height: 500px;
        background: #fff;
        border-radius: 20px;
        box-shadow: 0px 4px 50px rgb(0 0 0 / 11%);
        display: flex;
        flex-direction: column;
        padding: 50px 40px;
        box-sizing: border-box;
        gap: 20px;
    }
    &--background {
        background: url('../../../public/login-background.png');
        position: absolute;
        top: -50px;
        left: 300px;
        right: 0;
        z-index: -1;
        bottom: -50px;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
    }
    .title--large {
        margin-bottom: 20px;
    }
    .button--large {
        margin-top: 15px;
    }
    .breakline {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}