.profile {
    display: flex;
    justify-content: space-between;
    &-user {
        font-size: 24px;
        color: #000000;
        font-weight: 300;
    }
    &-options {
        cursor: pointer;
        position: relative;
        &--icon {
            color: #000000;
            font-size: 24px;
        }
        &--dropdown {
            position: absolute;
            top: -5px;
            right: -7px;
            bottom: -8px;
            left: -32px;
            &:hover {
                > div {
                    display: block;
                }
            }
            &--container {
                display: none;
                background: #fff;
                box-shadow: 0px 2px 10px #0000001c;
                padding: 10px 18px;
                border-radius: 5px;
                position: absolute;
                top: 36px;
                right: 0px;
                width: 150px;
                box-sizing: border-box;
                color: #000;
                a {
                    color: #000;
                }
            }
        }
    }
}