.news {
    margin-top: 50px;
    h4 {
        margin-bottom: 12px;
        color: #000000;
        font-weight: 300;
    }

    &-item {
        color: #000;
        border-radius: 10px;
        padding: 6px 20px;
        margin-bottom: 20px;
        box-shadow: 0px 2px 20px rgb(0 0 0 / 6%);
        h3 {
            margin-top: 10px;
            font-size: 16px;
            margin-bottom: 5px;
            font-weight: 600;
            color: #000
        }
        p {
            margin-top: 10px;
            color: #898989;
            font-size: 14px;
            line-height: 20px;
        }
        &--date {
            margin-bottom: 4px;
            font-size: 12px;
            color: #d1d1d1;
            font-weight: bold;
        }
    }
}