.title {
    &--large {
        font-weight: 300;
        font-size: 36px;
        margin: 0px;
    }
    &--medium {
        font-weight: 300;
        font-size: 24px;
        margin: 0
    }
}

.text {
    &--small {
        font-size: 14px;
        margin: 0;
        color: #5c5c5c;
    }
}