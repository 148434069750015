.input {
    &--container {
        display: flex;
        flex-direction: column;
        position: relative;

        input {
            height: 50px;
            border-radius: 8px;
            border: 1px solid #eae8e8;
            box-shadow: 0px 0px 10px rgb(0 0 0 / 5%);
            margin-top: 10px;
            padding: 0px 10px;
            font-size: 16px;
            transition: 0.2s all ease-in-out;
            &:focus {
                outline: none;
                box-shadow: 0px 0px 10px rgb(131 166 231 / 33%);
                border: 1px solid #9cafec;
            }
        }

        &__invalid {
            color: red;
            input {
                border: 1px solid red;
                box-shadow: 0px 0px 10px rgb(255 0 0 / 5%);
            }
        }

    }

    &--label {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        &--error {
            font-size: 12px;
            color: red;
            position: absolute;
            right: 15px;
            bottom: 7px;
        }
    }

    &--cta {
        position: absolute;
        right: 0px;
        font-size: 14px;
        font-weight: 600;
        color: #000;
        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }
}