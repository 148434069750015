.apps {
    &--container {
        flex-grow: 1;
        padding: 40px;
        .title--medium {
            margin-bottom: 10px;
            margin-top: 10px;
        }
    }

    &-favorites--container, &-list--container {
        margin-bottom: 50px;
    }
    &-favorites {
        &--items {
            display: flex;
            gap: 20px;
            margin-top: 30px;
            flex-wrap: wrap;
        }
    }
    &-list--items {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-top: 30px;
        
    }
    &-listitem {
        display: flex;
        justify-content: space-between;
        padding: 15px 20px;
        border: 1px solid #ebe9e9;
        border-radius: 8px;

        &--name {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;

            p {
                margin: 0px;
                font-weight: 600;
            }

            p.description {
                margin: 0px;
                border-left: 1px solid #ccc;
                padding-left: 20px;
                font-weight: 300;
            }
            
            svg {
                width: 20px;
                height: 20px;
            }
        }
        &-actions {
            display: flex;
            gap: 20px;
            align-items: center;
        }
        &--favorite {
            cursor:pointer;
            color: #868686;
            &--active {
                color: #fabe46;
            }
        }
    }
    &--item {
        width: 130px;
        height: 130px;
        background: #34984f;
        color: #fff;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        box-shadow: inset 0px 0px 1px rgb(0 0 0 / 0%);
        transition: 0.2s all ease-in-out;

        &:hover {
            cursor: pointer;
            box-shadow: inset 0px 0px 100px rgb(0 0 0 / 18%);
        }

        &--inner {
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-align: center;
            flex-direction: column;
            height: 100%;
            box-sizing: border-box;
            padding: 27px 10px;

            p {
                font-size: 14px;
                margin: 0px;
                letter-spacing: 0.3px;
                font-weight: 400;
            }
            svg {
                width: 35px;
                height: 35px;
            }
        }
    }
}